import React, { FC, useState, useRef } from 'react'
import { CloseCircle } from '@aether/icons'
import { useTranslation } from 'react-i18next'
import { styled } from '@aether/styles'
import { ProductSizeGuideType } from '@aether/models'
import { HowToMeasure } from './HowToMeasure'
import { FitGuide } from './FitGuide'
import { SizeChart } from './SizeChart'

export type SizeGuideProps = {
  closeSizeGuide: () => void
  sizeGuide: ProductSizeGuideType | undefined
}

const StyledSizeGuide = styled('div', {
  position: 'relative',
  padding: '$24',
  backgroundColor: '$white',

  '@md': {
    padding: '$40',
    $lin: {
      width: ['650px', '900px'],
    },
  },
  '@lg': {
    padding: '$64',
  },
})

const StyledCloseButton = styled('button', {
  position: 'absolute',
  background: 'none',
  right: '$12',
  top: '$12',
  padding: '$8',
  $focusStyle: 'default',
})

const SizeGuideTitle = styled('h1', {
  $aetherFont: 'heading03',
  marginBottom: '$36',
})

const TabsContainer = styled('div', {
  display: 'flex',
  marginBottom: '$32',
  width: '100%',
  overflowX: 'scroll',
  overflowY: 'auto',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  $lin: {
    scrollPaddingLeft: 'CONTAINER_SPACE_M',
  },

  '@md': {
    width: 'unset',
    paddingRight: '0',
  },
})

const Tab = styled('div', {
  backgroundColor: '$white',
  textAlign: 'left',
  margin: 0,
  color: '$gray_medium',
  cursor: 'pointer',
  $aetherFont: 'heading06',
  padding: '$12 0',
  width: '100%',
  flex: '0 0 40%',
  flexFlow: 'column',
  minWidth: 'auto',
  marginRight: '$12',
  scrollSnapAlign: 'start',
  $focusStyle: 'negativeOffset',

  variants: {
    isActive: {
      true: {
        color: '$black',
        borderTop: '2px solid $black',
      },
      false: {
        borderTop: '2px solid $gray_medium',
      },
    },
  },
  '@md': {
    flex: 'unset',
    marginRight: '$0',
  },
})

export const SizeGuide: FC<SizeGuideProps> = ({
  closeSizeGuide,
  sizeGuide,
}) => {
  const { t } = useTranslation('sizeGuide')
  const [activeView, setActiveView] = useState<number>(0)

  type TabValuesTypes = {
    [key: number]: {
      title: string
      ref: any
    }
  }

  const tabValues: TabValuesTypes = {
    0: {
      title: 'sizeCharts',
      ref: useRef(null),
    },
    1: {
      title: 'fitGuide',
      ref: useRef(null),
    },
    2: {
      title: 'howToMeasure',
      ref: useRef(null),
    },
  }

  if (!sizeGuide) return null

  // do not show tabs if no fitgudes and how to measure are st up
  const sizeGuideAllOptions = [
    ...(sizeGuide?.fitGuide || []),
    ...(sizeGuide.howToMeasure || []),
  ]

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    const tabCount = Object.keys(tabValues).length - 1

    if (event.key === 'ArrowLeft') {
      const last = tabCount
      const next = activeView - 1
      handleNextTab(last, next, 0)
    }
    if (event.key === 'ArrowRight') {
      const first = 0
      const next = activeView + 1
      handleNextTab(first, next, tabCount)
    }
  }

  const handleNextTab = (
    firstTabInRound: number,
    nextTab: number,
    lastTabInRound: number,
  ) => {
    const tabToSelect =
      activeView === lastTabInRound ? firstTabInRound : nextTab
    setActiveView(tabToSelect)
    tabValues[tabToSelect].ref.current.focus()
  }

  return (
    <StyledSizeGuide>
      <SizeGuideTitle>{t('sizeGuide')}</SizeGuideTitle>

      {sizeGuideAllOptions.length ? (
        <TabsContainer role="tablist" onKeyDown={handleKeyPress}>
          {Object.keys(tabValues).map((tab, index) => (
            <Tab
              key={`${tab} -${index}`}
              onClick={() => setActiveView(index)}
              isActive={activeView === index}
              aria-selected={activeView === index}
              tabIndex={activeView === index ? 0 : -1}
              ref={tabValues[index].ref}
              role="tab"
            >
              {t(`${tabValues[index].title}`)}
            </Tab>
          ))}
        </TabsContainer>
      ) : null}

      {(() => {
        switch (tabValues[activeView].title) {
          case 'sizeCharts':
            return (
              <SizeChart
                table={sizeGuide?.table}
                title={sizeGuide.title}
                caption={sizeGuide.caption}
              />
            )
          case 'fitGuide':
            return sizeGuide?.fitGuide?.length ? (
              <FitGuide fitGuide={sizeGuide.fitGuide} />
            ) : null
          case 'howToMeasure':
            return sizeGuide.howToMeasure?.length ? (
              <HowToMeasure
                howToMeasure={sizeGuide.howToMeasure}
                image={sizeGuide.howToMeasureImage}
                title={sizeGuide.title}
              />
            ) : null
          default:
            return null
        }
      })()}

      <StyledCloseButton onClick={closeSizeGuide} aria-label="Close">
        <CloseCircle />
      </StyledCloseButton>
    </StyledSizeGuide>
  )
}
