import { createShopifyClient } from '@aether/services/shopify-service'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { gql } from 'graphql-request'
import { PRODUCT_VARIANT_FRAGMENT } from '@aether/services/shopify-service'
import { ShopifyPrice, ShopifyProductVariant } from '@aether/models'

type CustomerClientProps = {
  locale?: string
}

export const ORDER_LINE_FRAGMENT = gql`
  fragment OrderLineFragment on OrderLineItem {
    quantity
    title
    originalTotalPrice {
      currencyCode
      amount
    }
    discountedTotalPrice {
      currencyCode
      amount
    }
    customAttributes {
      value
      key
    }
    variant {
      ...ProductVariantFragment
    }
  }
`

const MAILING_ADDRESS_FRAGMENT = gql`
  fragment MailingAddressFragment on MailingAddress {
    id
    lastName
    firstName
    address1
    address2
    province
    country
    zip
    city
    company
    phone
  }
`

const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    name
    orderNumber
    financialStatus
    fulfillmentStatus
    processedAt
    statusUrl

    shippingAddress {
      ...MailingAddressFragment
    }
    lineItems(first: 200) {
      edges {
        node {
          ...OrderLineFragment
        }
      }
    }
    currentTotalPrice {
      currencyCode
      amount
    }
    totalTax {
      currencyCode
      amount
    }
    subtotalPrice {
      currencyCode
      amount
    }
  }
`

const CUSTOMER_FRAGMENT = gql`
  ${ORDER_LINE_FRAGMENT}
  ${MAILING_ADDRESS_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  fragment CustomerFragment on Customer {
    id
    firstName
    lastName
    acceptsMarketing
    email
    phone
    createdAt
    vipCustomer: metafield(key: "vip_customer", namespace: "customer") {
      key
      value
    }
    insiderMember: metafield(key: "insider_member", namespace: "custom") {
      key
      value
    }
    insiderMemberSince: metafield(
      key: "insider_member_since"
      namespace: "custom"
    ) {
      key
      value
    }
    defaultAddress {
      ...MailingAddressFragment
    }
    addresses(first: 20) {
      nodes {
        ...MailingAddressFragment
      }
    }
    orders(first: 250, reverse: true) {
      edges {
        node {
          ...OrderFragment
        }
      }
    }
  }
`

export const QUERY_CUSTOMER = gql`
  ${CUSTOMER_FRAGMENT}
  query ($customerAccessToken: String!, $countryCode: CountryCode)
  @inContext(country: $countryCode) {
    customer(customerAccessToken: $customerAccessToken) {
      ...CustomerFragment
    }
  }
`

export const MUTATION_CREATE_CUSTOMER = gql`
  ${CUSTOMER_FRAGMENT}
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        ...CustomerFragment
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const MUTATION_RECOVER_CUSTOMER = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`
export const MUTATION_RESET_CUSTOMER = gql`
  ${CUSTOMER_FRAGMENT}
  mutation resetPasswordByUrl($resetUrl: URL!, $password: String!) {
    customerResetByUrl(resetUrl: $resetUrl, password: $password) {
      customer {
        ...CustomerFragment
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const MUTATION_UPDATE_CUSTOMER = gql`
  ${CUSTOMER_FRAGMENT}
  mutation customerUpdate(
    $customer: CustomerUpdateInput!
    $customerAccessToken: String!
  ) {
    customerUpdate(
      customer: $customer
      customerAccessToken: $customerAccessToken
    ) {
      customer {
        ...CustomerFragment
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const MUTATION_CREATE_CUSTOMER_ACCESS_TOKEN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const MUTATION_DELETE_CUSTOMER_ACCESS_TOKEN = gql`
  mutation customerAccessTokenDelete($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        field
        message
      }
    }
  }
`

export const MUTATION_RENEW_CUSTOMER_ACCESS_TOKEN = gql`
  mutation customerAccessTokenRenew($customerAccessToken: String!) {
    customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const MUTATION_CREATE_CUSTOMER_ADDRESS = gql`
  mutation customerAddressCreate(
    $customerAccessToken: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerUserErrors {
        code
        field
        message
      }
      customerAddress {
        id
      }
    }
  }
`
export const MUTATION_DELETE_CUSTOMER_ADDRESS = gql`
  mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
    customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
      customerUserErrors {
        code
        field
        message
      }
      deletedCustomerAddressId
    }
  }
`

export const MUTATION_UPDATE_CUSTOMER_ADDRESS = gql`
  mutation customerAddressUpdate(
    $address: MailingAddressInput!
    $customerAccessToken: String!
    $id: ID!
  ) {
    customerAddressUpdate(
      address: $address
      customerAccessToken: $customerAccessToken
      id: $id
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`
export const MUTATION_UPDATE_CUSTOMER_DEFAULT_ADDRESS = gql`
  ${CUSTOMER_FRAGMENT}
  mutation customerDefaultAddressUpdate(
    $addressId: ID!
    $customerAccessToken: String!
  ) {
    customerDefaultAddressUpdate(
      addressId: $addressId
      customerAccessToken: $customerAccessToken
    ) {
      customer {
        ...CustomerFragment
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

export const MUTATION_ACTIVATE_USER = gql`
  ${CUSTOMER_FRAGMENT}
  mutation ($activationUrl: URL!, $password: String!) {
    customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
      customer {
        ...CustomerFragment
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

// response types
export type ErrorResponseType = {
  code: string
  field?: [string, string]
  message: string
}

export type TokenResponseType = { accessToken: string; expiresAt: string }

export type AddressResponseType = {
  id?: string
  lastName?: string
  firstName?: string
  address1?: string
  address2?: string
  company?: string
  phone?: string
  province?: string
  country?: string
  zip?: string
  city?: string
}

export type OrderLineItemResponseType = {
  quantity: number
  title: string
  originalTotalPrice: ShopifyPrice
  discountedTotalPrice: ShopifyPrice
  variant: ShopifyProductVariant
  customAttributes: { key: string; value: string }[]
}

export type OrderResponseType = {
  id?: string
  name?: string
  orderNumber?: string
  financialStatus?: string
  fulfillmentStatus?: string
  processedAt?: string
  statusUrl?: string
  shippingAddress?: AddressResponseType
  // This filed does not exist yet!
  // https://community.shopify.com/c/shopify-apis-and-sdks/storefront-graphql-discounts-and-billing-address/td-p/1280727
  billingAddress?: AddressResponseType
  currentTotalPrice?: ShopifyPrice
  totalTax?: ShopifyPrice
  subtotalPrice?: ShopifyPrice
  lineItems: {
    edges: { node?: OrderLineItemResponseType }[]
  }
}

export type CustomerResponseType = {
  id?: string
  firstName?: string
  lastName?: string
  acceptsMarketing?: string
  email?: string
  phone?: string
  defaultAddress?: { id?: string }
  createdAt?: string
  vipCustomer?: { key: 'vip_customer'; value: string }
  insiderMember?: { key: 'insider_member'; value: string }
  insiderMemberSince?: { key: 'insider_member_since'; value: string }
  addresses?: {
    nodes?: AddressResponseType[]
  }
  orders?: {
    edges: {
      node?: OrderResponseType
    }[]
  }
}

export type GetCustomerResponse = {
  customer?: CustomerResponseType
}

export type DeleteCustomerAccessTokenResponse = {
  customerAccessTokenDelete?: {
    deletedAccessToken?: string
    deletedCustomerAccessTokenId?: string
    userErrors?: ErrorResponseType[]
  }
}

export type ActivateCustomerResponse = {
  customerActivateByUrl?: {
    customer?: CustomerResponseType
    customerUserErrors?: ErrorResponseType[]
  }
}

export type UpdateCustomerDefaultAddressResponse = {
  customerDefaultAddressUpdate?: {
    customer?: CustomerResponseType
    customerUserErrors?: ErrorResponseType[]
  }
}

export type CreateCustomerAccessTokenResponse = {
  customerAccessTokenCreate?: {
    customerAccessToken?: TokenResponseType
    customerUserErrors?: ErrorResponseType[]
  }
}

export type RenewCustomerAccessTokenResponse = {
  customerAccessTokenRenew?: {
    customerAccessToken?: TokenResponseType
    userErrors?: ErrorResponseType[]
  }
}

export type CreateCustomerResponse = {
  customerCreate?: {
    customer?: CustomerResponseType
    customerUserErrors?: ErrorResponseType[]
  }
}

export type UpdateCustomerResponse = {
  customerUpdate?: {
    customer?: CustomerResponseType
    customerAccessToken?: TokenResponseType
    customerUserErrors?: ErrorResponseType[]
  }
}

export type ResetCustomerResponse = {
  customerResetByUrl?: {
    customer?: CustomerResponseType
    customerUserErrors?: ErrorResponseType[]
  }
}

export type RecoverCustomerResponse = {
  customerRecover?: {
    customerUserErrors?: ErrorResponseType[]
  }
}

export type CreateCustomerAddressResponse = {
  customerAddressCreate?: {
    customerUserErrors?: ErrorResponseType[]
    customerAddress?: AddressResponseType
  }
}

export type UpdateCustomerAddressResponse = {
  customerAddressUpdate?: {
    customerUserErrors?: ErrorResponseType[]
    customerAddress?: AddressResponseType
  }
}

export type DeleteCustomerAddressResponse = {
  customerAddressDelete?: {
    customerUserErrors?: ErrorResponseType[]
    deletedCustomerAddressId?: string
  }
}

// input types

type CustomerAddressInputType = {
  lastName?: string
  firstName?: string
  address1?: string
  address2?: string
  company?: string
  phone?: string
  province?: string
  country?: string
  zip?: string
  city?: string
}

type UpdateCustomerAddressInput = {
  customerAccessToken?: string
  id?: string
  address?: CustomerAddressInputType
}

type UpdateCustomerDefaultAddressInput = {
  addressId?: string
  customerAccessToken?: string
}

type DeleteCustomerAddressInput = {
  customerAccessToken?: string
  id?: string
}

type CreateCustomerAddressInput = {
  customerAccessToken?: string
  address?: CustomerAddressInputType
}

type CreateCustomerInput = {
  acceptsMarketing?: boolean
  email?: string
  firstName?: string
  lastName?: string
  password?: string
  phone?: string
}

type RecoverCustomerInput = {
  email?: string
}

type ResetCustomerInput = {
  resetUrl: string
  password: string
}

type UpdateCustomerInput = {
  customer: {
    acceptsMarketing?: boolean
    email?: string
    firstName?: string
    lastName?: string
    password?: string
    phone?: string
  }
  customerAccessToken?: string
}

type CreateCustomerTokenInput = {
  email?: string
  password?: string
}

type DeleteCustomerAccessTokenInput = {
  customerAccessToken?: string
}

type RenewCustomerAccessTokenInput = {
  customerAccessToken?: string
}

type GetCustomerInput = {
  customerAccessToken?: string
  countryCode?: string
}

type ActivateCustomerInput = {
  activationUrl: string
  password: string
}

type CustomerClient = {
  create: (input: CreateCustomerInput) => Promise<CreateCustomerResponse | null>
  update: (input: UpdateCustomerInput) => Promise<UpdateCustomerResponse | null>
  reset: (input: ResetCustomerInput) => Promise<ResetCustomerResponse | null>
  get: (input: GetCustomerInput) => Promise<GetCustomerResponse | null>
  activate: (
    input: ActivateCustomerInput,
  ) => Promise<ActivateCustomerResponse | null>
  recover: (
    input: RecoverCustomerInput,
  ) => Promise<RecoverCustomerResponse | null>
  createAddress: (
    input: CreateCustomerAddressInput,
  ) => Promise<CreateCustomerAddressResponse | null>
  updateAddress: (
    input: UpdateCustomerAddressInput,
  ) => Promise<UpdateCustomerAddressResponse | null>
  updateDefaultAddress: (
    input: UpdateCustomerDefaultAddressInput,
  ) => Promise<UpdateCustomerDefaultAddressResponse | null>
  deleteAddress: (
    input: DeleteCustomerAddressInput,
  ) => Promise<DeleteCustomerAddressResponse | null>
  createToken: (
    input: CreateCustomerTokenInput,
  ) => Promise<CreateCustomerAccessTokenResponse | null>
  deleteToken: (
    input: DeleteCustomerAccessTokenInput,
  ) => Promise<DeleteCustomerAccessTokenResponse | null>
  renewToken: (
    input: RenewCustomerAccessTokenInput,
  ) => Promise<RenewCustomerAccessTokenResponse | null>
}

export const createCustomerClient = ({
  locale,
}: CustomerClientProps): CustomerClient => {
  const [countryCode, language] = getLocaleRegionIdFromPath(locale)
  const client = createShopifyClient(language)

  return {
    // customer
    create: async (input) =>
      await client.request<
        CreateCustomerResponse,
        { input: CreateCustomerInput }
      >(MUTATION_CREATE_CUSTOMER, { input }),

    update: async (input) =>
      await client.request<
        UpdateCustomerResponse,
        { input: UpdateCustomerInput }
      >(MUTATION_UPDATE_CUSTOMER, { input }),

    activate: async (input) =>
      await client.request<ActivateCustomerResponse, ActivateCustomerInput>(
        MUTATION_ACTIVATE_USER,
        input,
      ),

    recover: async (input) =>
      await client.request<RecoverCustomerResponse, RecoverCustomerInput>(
        MUTATION_RECOVER_CUSTOMER,
        input,
      ),

    reset: async (input) => {
      return await client.request<ResetCustomerResponse, ResetCustomerInput>(
        MUTATION_RESET_CUSTOMER,
        input,
      )
    },

    get: async (input) =>
      await client.request<GetCustomerResponse, GetCustomerInput>(
        QUERY_CUSTOMER,
        { countryCode, ...input },
      ),

    // token
    createToken: async (input) =>
      client.request<
        CreateCustomerAccessTokenResponse,
        { input: CreateCustomerTokenInput }
      >(MUTATION_CREATE_CUSTOMER_ACCESS_TOKEN, { input }),

    deleteToken: async (input) =>
      client.request<
        DeleteCustomerAccessTokenResponse,
        DeleteCustomerAccessTokenInput
      >(MUTATION_DELETE_CUSTOMER_ACCESS_TOKEN, input),

    renewToken: async (input) =>
      client.request<
        RenewCustomerAccessTokenResponse,
        RenewCustomerAccessTokenInput
      >(MUTATION_RENEW_CUSTOMER_ACCESS_TOKEN, input),

    // address
    createAddress: async (input) =>
      client.request<CreateCustomerAddressResponse, CreateCustomerAddressInput>(
        MUTATION_CREATE_CUSTOMER_ADDRESS,
        input,
      ),

    deleteAddress: async (input) =>
      client.request<DeleteCustomerAddressResponse, DeleteCustomerAddressInput>(
        MUTATION_DELETE_CUSTOMER_ADDRESS,
        input,
      ),

    updateAddress: async (input) =>
      client.request<UpdateCustomerAddressResponse, UpdateCustomerAddressInput>(
        MUTATION_UPDATE_CUSTOMER_ADDRESS,
        input,
      ),

    updateDefaultAddress: async (input) =>
      client.request<
        UpdateCustomerDefaultAddressResponse,
        UpdateCustomerDefaultAddressInput
      >(MUTATION_UPDATE_CUSTOMER_DEFAULT_ADDRESS, input),
  }
}
