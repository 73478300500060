import { Account, AccountFill, Close, Logo, Search } from '@aether/icons'
import { HeaderAppearanceType, SiteConfiguration } from '@aether/models'
import { colors, styled } from '@aether/styles'
import React, { FC, useState } from 'react'
import { Button, Container, Link } from '@aether/components'
import { Quantity } from './Quantity'
import { useCartContext } from '@aether/cart'
import { ROUTE_BASE_ACCOUNT_HOME } from '@aether/configs'
import Div100vh from 'react-div-100vh'
import { useCustomerContext } from '@aether/account/utils-customer-context'
import { useTranslation } from 'react-i18next'

const Wrap = styled('div', {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  display: 'grid',
  variants: {
    appearance: {
      journal: {
        backgroundColor: '$beige',
      },
      default: {
        backgroundColor: '$white',
      },
    },
  },
})
const Content = styled('div', {
  width: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
})

const DrawerHeader = styled(Container, {
  variants: {
    appearance: {
      journal: {
        backgroundColor: '$beige',
      },
      default: {
        backgroundColor: '$white',
      },
    },
  },
})

const TopHeader = styled('div', {
  height: '$headerHeightNormal',
  display: 'grid',
  position: 'relative',
  gridTemplateColumns: '44px auto 44px',
  alignItems: 'center',
  margin: '0 -10px 0 -12px',
  gridGap: '$48',
  zIndex: 10,
})
const Nav = styled('div', {
  display: 'grid',
  gridTemplateColumns: '44px auto 44px',
  margin: '0 -12px',
  gap: '$16',
  alignItems: 'center',
  paddingBottom: '$12',
})
const IconWrap = styled('div', {
  justifySelf: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})

const StyledButton = styled(Button, {
  width: 44,
  height: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const LogoWrap = styled('div', {
  maxWidth: 200,
  justifySelf: 'center',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
})

const TabContainer = styled(Container, {
  overflowY: 'auto',
  height: '100%',
})

const TabNavigation = styled('div', {
  display: 'grid',

  '@md': {
    gridTemplateColumns: 'repeat(auto-fill, 300px)',
    gap: '$24',
  },
})

const ColHeading = styled('div', {
  borderTop: '1px solid black',
  padding: '$20 0',
  $aetherFont: 'heading06',
  textTransform: 'uppercase',
})
const ColItems = styled('div', {
  display: 'grid',
  paddingBottom: '$32',
  gap: '$16',
})
const ColItem = styled('div', {
  $aetherFont: 'ui01',
  variants: {
    isHighlighted: {
      true: {
        color: '$red',
      },
    },
  },
})

const TabsWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$4',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  '@sm': {
    display: 'flex',
    justifyContent: 'center',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

type ContentProps = {
  onCloseClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
  header: SiteConfiguration['header']
  appearance: HeaderAppearanceType
  openSearch: () => void
}

export const MobileDrawer: FC<ContentProps> = ({
  onCloseClick,
  header,
  appearance,
  openSearch,
}) => {
  const [openTabIndex, setOpenTabIndex] = useState<number>(0)
  const { quantity, setCartModalOpen } = useCartContext()
  const { isLoggedIn, isVip } = useCustomerContext()
  const { t } = useTranslation('header')

  return (
    <Div100vh>
      <Wrap appearance={appearance}>
        <Content>
          <DrawerHeader appearance={appearance}>
            <TopHeader>
              <StyledButton
                appearance="block"
                onClick={onCloseClick}
                ariaLabel={t('searchDrawer.closeLabel') || undefined}
              >
                <Close />
              </StyledButton>
              <LogoWrap>
                <Link href={`/`} appearance="block">
                  <Logo />
                </Link>
              </LogoWrap>
              <Quantity
                quantity={quantity}
                onClick={() => setCartModalOpen(true)}
              />
            </TopHeader>
            <Nav>
              <StyledButton
                appearance="block"
                onClick={openSearch}
                ariaLabel={t('searchDrawer.searchModal.openLabel') || undefined}
              >
                <IconWrap>
                  <Search />
                </IconWrap>
              </StyledButton>
              <TabsWrap
                role="tablist"
                aria-label="Categories"
                onKeyDown={(event) => {
                  if (!header?.tabs) {
                    return
                  }

                  let nextIndex = -1

                  if (event.key === 'ArrowRight') {
                    nextIndex = (openTabIndex + 1) % header.tabs.length
                  }

                  if (event.key === 'ArrowLeft') {
                    nextIndex =
                      (openTabIndex - 1 + header.tabs.length) %
                      header.tabs.length
                  }

                  if (nextIndex > -1) {
                    event.preventDefault()

                    setOpenTabIndex(nextIndex)

                    const nextElement = event.currentTarget.children[nextIndex]

                    if (nextElement instanceof HTMLButtonElement) {
                      nextElement.focus()
                    }
                  }
                }}
              >
                {header &&
                  header?.tabs?.map((tab, i) => (
                    <Button
                      key={tab.title}
                      appearance="badgeSlim"
                      onClick={() => setOpenTabIndex(i)}
                      active={openTabIndex === i}
                      role="tab"
                      id={`tab-${tab.title}`}
                      aria-selected={openTabIndex === i}
                      aria-controls={`tabpanel-${tab.title}`}
                      tabIndex={openTabIndex === i ? 0 : -1}
                    >
                      {tab.title}
                    </Button>
                  ))}
              </TabsWrap>
              <StyledButton as={Link} href={ROUTE_BASE_ACCOUNT_HOME}>
                <IconWrap>
                  {isLoggedIn ? (
                    <AccountFill fill={isVip ? colors.blue : 'black'} />
                  ) : (
                    <Account />
                  )}
                </IconWrap>
              </StyledButton>
            </Nav>
          </DrawerHeader>

          <TabContainer>
            <TabNavigation>
              {header?.tabs &&
                header?.tabs[openTabIndex].linkGroups.map((linkGroup) => {
                  return (
                    linkGroup.title && (
                      <div
                        key={linkGroup.title}
                        role="tabpanel"
                        aria-labelledby={`tab-${header?.tabs?.[openTabIndex].title}`}
                      >
                        {linkGroup.groupLink && linkGroup.groupLink.href ? (
                          <ColHeading>
                            <Link {...linkGroup.groupLink}>
                              {linkGroup.title}
                            </Link>
                          </ColHeading>
                        ) : (
                          <ColHeading>{linkGroup.title}</ColHeading>
                        )}
                        <ColItems>
                          {linkGroup.links?.map((link) => {
                            return (
                              link.payload?.label && (
                                <ColItem
                                  key={link.payload.label}
                                  isHighlighted={link.payload?.isHighlighted}
                                >
                                  <Link {...link.payload?.link}>
                                    {link.payload?.label}
                                  </Link>
                                </ColItem>
                              )
                            )
                          })}
                        </ColItems>
                      </div>
                    )
                  )
                })}
            </TabNavigation>
          </TabContainer>
        </Content>
      </Wrap>
    </Div100vh>
  )
}
