export const AetherIcon = ({ fill = 'black' }: { fill?: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14C0 6.29293 6.29293 0 14 0C21.7424 0 28 6.29293 28 14C28 21.7071 21.7071 28 14 28C6.29293 28 0 21.7071 0 14ZM13.3283 27.0455H14C21.2121 27.0455 27.0455 21.2121 27.0455 13.9646C27.0455 7 21.5657 1.30808 14.6717 0.954545H14C6.78788 0.954545 0.954545 6.78788 0.954545 14C0.954545 21 6.43434 26.6919 13.3283 27.0455ZM14.0352 5.16139L18.3837 15.1664C18.207 15.2018 17.5706 15.3432 17.5706 15.3432L14.0352 7.21189L11.9847 11.9846C11.8787 11.9846 11.0655 12.0907 11.0655 12.0907L14.0352 5.16139ZM19.6918 20.2927L18.4544 17.3937C18.4544 17.3937 19.1969 17.2169 19.2322 17.2169L20.4342 19.9745L19.6918 20.2927ZM10.0756 14.424L7.70696 19.9392L8.44938 20.2573L11.0302 14.2472C11.0302 14.2472 10.5352 14.3533 10.0756 14.424ZM18.5606 13.8937C19.7979 14.2119 20.4697 14.6008 20.4697 14.8836C20.4697 15.3786 18.1717 16.3331 13.9646 16.3331C12.8333 16.3331 11.808 16.2624 10.9596 16.1563L10.606 16.8988C11.6666 17.0402 12.8333 17.1109 13.9646 17.1109C17.5 17.1109 21.2828 16.4038 21.2828 14.8483C21.2828 13.9291 19.9747 13.2927 18.1363 12.9392L18.5606 13.8937ZM13.9646 12.5503C14.5303 12.5503 15.0959 12.5856 15.6616 12.621L16.0151 13.4341C15.3788 13.3988 14.707 13.3634 13.9646 13.3634C9.75754 13.3634 7.45956 14.318 7.45956 14.8129C7.45956 15.025 7.91916 15.3432 8.803 15.626L8.48481 16.3685C7.3535 16.0149 6.64643 15.4846 6.64643 14.8129C6.64643 13.2574 10.4293 12.5503 13.9646 12.5503Z"
        fill={fill}
      />
    </svg>
  )
}
