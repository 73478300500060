import { FC, useEffect, useState } from 'react'
import { ShopifyProductsData, ProductColorType } from '@aether/models'
import { styled } from '@aether/styles'
import { StackImagesSlider } from '../StackImagesSlider/StackImagesSlider'
import { SliderIndicator } from '../StackImagesSlider/SliderIndicator'
import { Link } from '../Link/Link'
import { ProductColorPicker } from '../ProductColorPicker/ProductColorPicker'
import { ProductPrice } from '../Price/ProductPrice/ProductPrice'

import { ROUTE_BASE_PRODUCTS } from '@aether/configs'
import { useStackImagesSlider } from '../StackImagesSlider/useStackIImagesSlider'
import { Badge } from '../Badge/Badge'
import { useTranslation } from 'react-i18next'
import { useCustomerContext } from '@aether/account/utils-customer-context'
import { useDiscounts } from '@aether/discounts/utils-context'
import { formatProductName } from '@aether/utils'

type DesktopItemProps = {
  productId: string
  shopifyData?: ShopifyProductsData
  colorsMap?: ProductColorType[]
  className?: string
}

const Card = styled('div', {
  display: 'grid',
})

const CardContent = styled('article', {
  position: 'relative',
  display: 'grid',
  width: '100%',
  gridColumn: 1,
  gridRow: 1,
  pointerEvents: 'none',
})

const LinkWrap = styled('div', {
  display: 'grid',
  gridColumn: 1,
  gridRow: 1,
})

const StyledBadge = styled(Badge, {
  position: 'absolute',
  top: '$24',
  left: '$24',
  zIndex: '$content',
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
})

const BottomWrap = styled('div', {
  gridRow: 2,
  gridColumn: 1,
  paddingTop: '$20',
  gap: '$12',
  display: 'grid',
  gridAutoFlow: 'row',
  gridTemplateColumns: 'auto 1fr',
})

const FeaturesContainer = styled('div', {
  gridRow: 2,
  gridColumn: '1 / 3',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '$16',
})

const FeatureTextWrap = styled('div', {
  $aetherFont: 'ui01',
  lineHeight: '15px',
})

const Title = styled('h5', {
  $aetherFont: 'heading05',
})

const LinkTitle = styled(Link, {
  gridRow: 1,
  gridColumn: 1,
  pointerEvents: 'all',
})

const StyledProductColorPicker = styled(ProductColorPicker, {
  gridRow: 3,
  gridColumn: '1 / 3',
  pointerEvents: 'all',
  zIndex: '$content',
})

const BottomBadgesWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$12',
  gridRow: 4,
  gridColumn: '1 / 3',
})

export const DesktopItem: FC<DesktopItemProps> = ({
  productId,
  shopifyData,
  colorsMap,
  className,
}) => {
  const { t } = useTranslation('product')
  const [isHovered, setIsHovered] = useState(false)

  const product = shopifyData?.products[productId]
  const collections =
    product?.collections?.edges.map((edge) => edge.node.id) || []
  const products = shopifyData?.products || {}
  const plpMainFeature = product?.plpMainFeature?.value || ''
  const productTitle = formatProductName(product?.title || '')
  const badge = product?.badge?.value
  const vipProduct = product?.vipProduct
  const greyBadgeValue = product?.greyBadge?.value

  const { userRole } = useCustomerContext()
  const isVip = userRole === 'vip'
  const { matchedDiscount } = useDiscounts({ collections, userRole, productId })
  const isVipDiscounted = isVip && matchedDiscount

  const simpleProductId = product?.id.replace('gid://shopify/Product/', '')

  /**
   * Display max 4 images
   */
  const images = product?.images.slice(0, 4) || []

  const isUnfolded = isHovered && images.length > 1

  const {
    activeSlideIndex,
    setNextSlideIndex,
    setPrevSlideIndex,
    setSlideIndex,
    onAnimationEnd,
    onAnimationStart,
  } = useStackImagesSlider(images)

  useEffect(() => {
    setSlideIndex(0)
  }, [productId])

  const isSoldOut = !product?.availableForSale
  return product ? (
    <div className={className}>
      <Card
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardContent>
          {badge && <StyledBadge text={badge} appearance="action" />}

          <MediaWrap>
            <StackImagesSlider
              mediaSizes="25vw"
              images={images}
              isUnfolded={isUnfolded}
              activeSlideIndex={activeSlideIndex}
              onNextSlideClick={setNextSlideIndex}
              onAnimationEnd={onAnimationEnd}
              onAnimationStart={onAnimationStart}
              onPrevSlideClick={setPrevSlideIndex}
              id={simpleProductId}
            />
          </MediaWrap>

          <BottomWrap>
            <LinkTitle
              href={`${ROUTE_BASE_PRODUCTS}/${product.handle}`}
              appearance="block"
            >
              <Title>{productTitle}</Title>
            </LinkTitle>
            {images.length > 1 && (
              <SliderIndicator
                images={images}
                activeSlideIndex={activeSlideIndex}
                onSlideClick={setSlideIndex}
                id={simpleProductId}
              />
            )}
            <FeaturesContainer>
              <FeatureTextWrap>
                {isSoldOut ? (
                  t('soldOut')
                ) : (
                  <ProductPrice product={product} discount={matchedDiscount} />
                )}
              </FeatureTextWrap>
              {plpMainFeature && (
                <FeatureTextWrap as="span">{plpMainFeature}</FeatureTextWrap>
              )}
            </FeaturesContainer>
            <StyledProductColorPicker
              colorsMap={colorsMap || []}
              productId={productId}
              products={products}
              titlePosition="right"
              productTitle={productTitle}
            />
            {(vipProduct || greyBadgeValue || isVipDiscounted) && (
              <BottomBadgesWrapper>
                {vipProduct && isVip && (
                  <Badge text={t('vipBadge')} appearance="vip" />
                )}
                {greyBadgeValue && (
                  <Badge text={greyBadgeValue} appearance="default" />
                )}
              </BottomBadgesWrapper>
            )}
          </BottomWrap>
        </CardContent>
        <LinkWrap>
          <Link
            href={`${ROUTE_BASE_PRODUCTS}/${product.handle}`}
            tabIndex={-1}
            aria-hidden={true}
          />
        </LinkWrap>
      </Card>
    </div>
  ) : null
}
