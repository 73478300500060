import { colors, styled, useMediaQuery } from '@aether/styles'
import {
  HeaderAppearanceType,
  HeaderTab,
  SiteConfiguration,
} from '@aether/models'
import {
  Account,
  AetherIcon,
  Logo,
  Menu,
  Search,
  AccountFill,
} from '@aether/icons'

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import { Quantity } from './Quantity'
import {
  Link,
  Container,
  Button,
  Modal,
  PortableText,
} from '@aether/components'
import { Clock } from './Clock'
import { useTranslation } from 'react-i18next'
import { MobileDrawer } from './MobileDrawer'
import { useCartContext } from '@aether/cart'
import { ROUTE_BASE_ACCOUNT_HOME } from '@aether/configs'
import dynamic from 'next/dynamic'
import { useCustomerContext } from '@aether/account/utils-customer-context'
import { DesktopMenuItem } from './DesktopMenuItem'
import { searchClient } from '@aether/search/feature'
import { InstantSearch } from 'react-instantsearch-hooks-web'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const SearchDrawer = dynamic(() =>
  import('@aether/search/feature').then((mod) => mod.SearchDrawer),
)

type Props = {
  appearance?: HeaderAppearanceType
  isStatic?: boolean
  header: SiteConfiguration['header']
  searchConfig: SiteConfiguration['search']
}

const HEADER_TRANSITION_DURATION = 150

const Root = styled('header', {
  zIndex: '$header',
  paddingBottom: 0,
  background: 'transparent',
  position: 'sticky',
  top: 'calc(-$notificationHeaderHeight - $headerHeightLarge)',
  transform: 'translateY(0)',
  variants: {
    isSmall: {
      true: {
        '@lg': {
          paddingBottom: 'calc($headerHeightLarge - $headerHeightNormal)',
        },
      },
    },
    isHidden: {
      true: {
        transform: 'translateY(-200px)',
      },
    },
    isScrollingUp: {
      true: {
        top: 'calc(-$notificationHeaderHeight)',
      },
    },
    isStatic: {
      true: {
        position: 'relative',
        top: 0,
      },
      false: {
        transition: `all ${HEADER_TRANSITION_DURATION}ms`,
      },
    },
  },
})

const NotificationHeader = styled(Container, {
  top: 0,
  overflow: 'hidden',
  height: '$notificationHeaderHeight',
  width: '100%',
  display: 'grid',
  gap: '$32',
  paddingTop: '$16',
  gridTemplateColumns: '1fr',
  '@lg': {
    gridTemplateColumns: '220px 1fr 220px',
  },
  variants: {
    appearance: {
      journal: {
        backgroundColor: '$beige',
      },
      default: {
        backgroundColor: '$white',
      },
    },
  },
})

const NotificationLeftText = styled('div', {
  display: 'none',
  $aetherFont: 'ui06',
  '@lg': {
    display: 'flex',
    alignItems: 'center',
  },
})

const BlackPill = styled('div', {
  width: '100%',
  maxWidth: '630px',
  justifySelf: 'center',
  textAlign: 'center',
  color: '$white',
  background: '$black',
  borderRadius: '$r1',
  lineHeight: 1,
  padding: '$8',
  $aetherFont: 'ui03',
  'a, button': {
    '&:hover': {
      color: '$beige',
    },
  },
})

const NotificationRightText = styled('div', {
  display: 'none',
  overflow: 'hidden',
  $aetherFont: 'ui06',
  '@lg': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ':first-child': {
      marginRight: '$12',
    },
  },
})

const HeaderWrap = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: '$headerHeightNormal',
  width: '100%',
  transition: `height ${HEADER_TRANSITION_DURATION}ms`,
  '@lg': {
    height: '$headerHeightLarge',
  },
  variants: {
    isSmall: {
      true: {
        height: '$headerHeightNormal',
      },
    },
    appearance: {
      journal: {
        backgroundColor: '$beige',
      },
      default: {
        backgroundColor: '$white',
      },
    },
    isStatic: {
      false: {
        transition: `height ${HEADER_TRANSITION_DURATION}ms`,
      },
    },
  },
})

const HeaderContent = styled(Container, {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '28px auto 28px',
  gridGap: '$48',
  alignItems: 'center',
  '@lg': {
    gridGap: '$32',
    gridTemplateColumns: '340px 1fr 340px',
  },
})

const NavWrap = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gap: '$16',
  gridAutoFlow: 'column',
})
const StyledList = styled('ol', {
  display: 'flex',
  listStyle: 'none',
})

const LogoWrap = styled('div', {
  maxWidth: 200,
  justifySelf: 'center',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
})
const IconWrap = styled('div', {
  justifySelf: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  $focusStyle: 'default',
})

const HeaderRight = styled('div', {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$14',
  marginRight: '-10px',
  '@lg': {
    gridTemplateColumns: 'repeat(3, auto)',
  },
})

const Visibility = styled('div', {
  variants: {
    device: {
      mobile: {
        '@lg': {
          display: 'none',
        },
      },
      desktop: {
        display: 'none',
        '@lg': {
          display: 'initial',
        },
      },
    },
  },
})

const UserUtilityButton = styled('button', {
  height: 44,
  width: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const LeftMobileContentWrapper = styled('div', { marginLeft: '-10px' })

const IconLink = styled(Link, {
  $focusStyle: 'default',
})
const StyledLogo = styled(Link, {
  $focusStyle: 'default',
})
const SkipToMainContent = styled('a', {
  left: '50%',
  top: 20,
  position: 'absolute',
  transform: 'translate(-50%,-100px)',
  zIndex: 110,
  transition: 'transform .3s',
  backgroundColor: '$black',
  color: '$white',
  display: 'inline-flex',
  $aetherFont: 'ui02',
  lineHeight: 1,
  borderRadius: '$r1',
  height: '$buttonHeightL',
  padding: '0 $20',
  alignItems: 'center',
  justifyContent: 'center',
  $focusStyle: 'default',
  '&:focus': {
    transform: 'translate(-50%,0px)',
  },
})
export const Header: FC<Props> = ({
  header,
  appearance = 'default',
  isStatic = false,
  searchConfig,
}) => {
  const router = useRouter()
  const { t } = useTranslation('header')
  const { quantity, setCartModalOpen } = useCartContext()
  const [scrollDown, setScrollDown] = useState(false)
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const [isSearchDrawerOpen, setSearchDrawerOpen] = useState(false)
  const [highlightedItemIndex, setHighlightedItemIndex] = useState<
    number | null
  >(null)
  const fullHeaderHeight = 163
  const refPrevScrollPos = useRef<number>(0)
  const [isBelowHeader, setIsBelowHeader] = useState(false)
  const isLg = useMediaQuery('lg')
  const { isLoggedIn, customer, isVip } = useCustomerContext()
  const [activeTab, setActiveTab] = useState<HeaderTab | undefined>()

  useEffect(() => {
    const w = window as any
    const klaviyo = w.klaviyo
    if (!klaviyo) return

    klaviyo.isIdentified((identified: boolean) => {
      if (!identified && customer?.email) {
        klaviyo.identify({ $email: customer?.email }, () => {
          window.location.reload()
        })
      }
    })
  }, [isLoggedIn])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos: number = window.scrollY
      if (currentScrollPos > fullHeaderHeight) {
        setIsBelowHeader(true)
      } else {
        setIsBelowHeader(false)
      }
      if (currentScrollPos > refPrevScrollPos.current && currentScrollPos > 0) {
        setScrollDown(true)
      } else {
        setScrollDown(false)
      }
      refPrevScrollPos.current = window.scrollY
    }
    if (!isStatic) {
      window.addEventListener('scroll', handleScroll, { passive: true })
    }

    if (isStatic) {
      setIsBelowHeader(false)
      setScrollDown(false)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isStatic])

  const closeDrawer = () => {
    setHighlightedItemIndex(null)
  }

  const openSearch = () => {
    setSearchDrawerOpen(true)
  }

  useEffect(() => {
    const handleRouteChange = () => {
      setHighlightedItemIndex(null)
      setMobileNavOpen(false)
      setSearchDrawerOpen(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, highlightedItemIndex])

  useEffect(() => {
    function closeNavigationOnEscape(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        setActiveTab(undefined)
      }
    }

    if (!activeTab) {
      return
    }

    window.addEventListener('keydown', closeNavigationOnEscape)

    return () => {
      window.removeEventListener('keydown', closeNavigationOnEscape)
    }
  }, [activeTab])

  const openNavigation = useCallback((tab: HeaderTab) => {
    setActiveTab(tab)
  }, [])

  const closeNavigation = useCallback(() => {
    setActiveTab(undefined)
  }, [])

  return header ? (
    <>
      <SkipToMainContent href="#main">{t('skipToContent')}</SkipToMainContent>
      <Root
        isStatic={!!isStatic}
        isSmall={isBelowHeader}
        isHidden={isBelowHeader && scrollDown}
        isScrollingUp={!scrollDown}
      >
        {header?.notificationHeader && (
          <NotificationHeader appearance={appearance}>
            <NotificationLeftText>
              {header?.notificationHeader?.leftText}
            </NotificationLeftText>
            <BlackPill>
              {header?.notificationHeader?.centerText && (
                <PortableText
                  value={header.notificationHeader.centerText}
                  withStyles={false}
                  withSpacing={false}
                />
              )}
            </BlackPill>
            <NotificationRightText>
              <span>{t('place')}</span>
              <Clock />
            </NotificationRightText>
          </NotificationHeader>
        )}
        <HeaderWrap
          isSmall={isBelowHeader}
          onMouseLeave={() => {
            closeDrawer()
            closeNavigation()
          }}
          appearance={appearance}
          isStatic={!!isStatic}
        >
          <HeaderContent>
            <Visibility device="desktop">
              {header.tabs && (
                <NavWrap>
                  <IconLink
                    href={`/`}
                    appearance="block"
                    ariaLabel="Aether home logo"
                  >
                    <IconWrap>
                      <AetherIcon />
                    </IconWrap>
                  </IconLink>

                  <nav aria-label={t('mainNavigation') || undefined}>
                    <StyledList
                      style={{
                        gridTemplateColumns: `repeat(${header?.tabs.length}, 1fr)`,
                      }}
                    >
                      {header.tabs.map((tab) => (
                        <DesktopMenuItem
                          tab={tab}
                          appearance={appearance}
                          isOpen={activeTab === tab}
                          onOpen={openNavigation}
                          onClose={closeNavigation}
                          key={`menu-tab-${tab.title}`}
                        />
                      ))}
                    </StyledList>
                  </nav>
                </NavWrap>
              )}
            </Visibility>
            <Visibility device="mobile">
              <LeftMobileContentWrapper>
                <UserUtilityButton
                  as={Button}
                  appearance="block"
                  aria-label={
                    isMobileNavOpen ? t('closeLabel') : t('openLabel')
                  }
                  aria-expanded={isMobileNavOpen}
                  onClick={() => setMobileNavOpen(!isMobileNavOpen)}
                >
                  <IconWrap>
                    <Menu />
                  </IconWrap>
                </UserUtilityButton>
              </LeftMobileContentWrapper>
            </Visibility>
            <LogoWrap>
              <StyledLogo href="/" appearance="block" ariaLabel="Home">
                <Logo />
              </StyledLogo>
            </LogoWrap>
            <HeaderRight>
              <Visibility device="desktop">
                <UserUtilityButton
                  as={Button}
                  appearance="block"
                  onClick={openSearch}
                  ariaLabel="Search"
                >
                  <IconWrap>
                    <Search />
                  </IconWrap>
                </UserUtilityButton>
              </Visibility>

              <Visibility device="desktop">
                <UserUtilityButton
                  as={Link}
                  href={ROUTE_BASE_ACCOUNT_HOME}
                  ariaLabel="My account"
                  appearance="block"
                >
                  <IconWrap>
                    {isLoggedIn ? (
                      <AccountFill fill={isVip ? colors.blue : 'black'} />
                    ) : (
                      <Account />
                    )}
                  </IconWrap>
                </UserUtilityButton>
              </Visibility>

              <Quantity
                quantity={quantity}
                onClick={() => setCartModalOpen(true)}
              />
            </HeaderRight>
          </HeaderContent>
        </HeaderWrap>
      </Root>
      <Modal
        preventScroll={true}
        isOpen={isMobileNavOpen}
        onRequestClose={() => setMobileNavOpen(false)}
        size={'stretch'}
      >
        <MobileDrawer
          onCloseClick={() => setMobileNavOpen(false)}
          header={header}
          appearance={appearance}
          openSearch={openSearch}
        />
      </Modal>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || ''}
      >
        <Modal
          preventScroll={true}
          isOpen={isSearchDrawerOpen}
          onRequestClose={() => setSearchDrawerOpen(false)}
          size={isLg ? 'stretch-x' : 'stretch'}
          position={'top'}
          transition={isLg ? 'slide-from-top' : 'fade'}
        >
          <SearchDrawer
            searchConfig={searchConfig}
            handleClose={() => setSearchDrawerOpen(false)}
          />
        </Modal>
      </InstantSearch>
    </>
  ) : null
}
