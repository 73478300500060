import { AppProps } from 'next/app'
import Head from 'next/head'
import { I18nextProvider } from 'react-i18next'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import Script from 'next/script'

import getI18n from '../translations/i18n'
import { PageResponse, PageBuilderSection } from '@aether/models'
import { styled, useMediaQuery } from '@aether/styles'
import { Modal } from '@aether/components'
import { Header } from '@aether/header'
import { Footer } from '@aether/footer'
import { MiniCart } from '@aether/cart'
import { useCartContext } from '@aether/cart'
import { FC, useEffect, useState, PropsWithChildren, useMemo } from 'react'
import { setAppElement } from 'react-modal'
import { CartProvider, ReturnsBanner } from '@aether/cart'
import { CustomerProvider } from '@aether/account/utils-customer-context'
import { setTag } from '@sentry/nextjs'
import { seoImageLoader } from '@aether/components'
import { ChatAppProvider } from '@aether/chat-app'
import Div100vh from 'react-div-100vh'
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { DiscountsProvider } from '@aether/discounts/utils-context'
import localFont from '@next/font/local'

export const maisonNeueBook = localFont({
  src: '../public/fonts/MaisonNeueWEB-Book.woff2',
  weight: '400',
  variable: '--font-maison-neue-book',
})
export const maisonNeueExtendedDemi = localFont({
  src: '../public/fonts/MaisonNeueExtendedWEB-Demi.woff2',
  weight: '500',
  variable: '--font-maison-neue-extended-demi',
})
export const maisonNeueMonoRegular = localFont({
  src: '../public/fonts/MaisonNeueMono-Regular.woff2',
  weight: '400',
  variable: '--font-maison-neue-mono-regular',
})

type ProjectAppProps = AppProps<PageResponse<PageBuilderSection[]>>

const StyledMiniCart = styled(MiniCart, {
  height: '100%',
  padding: '$24 $24 0 $24',
  width: '100%',
  '@md': {
    minWidth: '478px',
  },
})

const Layout: FC<PropsWithChildren<PageResponse<PageBuilderSection[]>>> = ({
  siteConfiguration,
  hideFooter,
  hideHeader,
  headerAppearance,
  isHeaderStatic,
  children,
}) => {
  const matchesMd = useMediaQuery('md')
  const { isCartModalOpen, setCartModalOpen } = useCartContext()

  return (
    <>
      <ReturnsBanner />
      <Head>
        <meta
          name="google-site-verification"
          content="753uiAzDJ_q8tU_O2b6n_YOjNLp_BrO7bNb_EpRH3gY"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link
          href="https://cdn.sanity.io/"
          rel="preconnect"
          crossOrigin="anonymous"
        />
        <link
          href="https://stream.mux.com"
          rel="preconnect"
          crossOrigin="anonymous"
        />
      </Head>

      {siteConfiguration && !hideHeader && (
        <Header
          header={siteConfiguration.header}
          searchConfig={siteConfiguration.search}
          appearance={headerAppearance}
          isStatic={isHeaderStatic}
        />
      )}

      {children}

      {siteConfiguration && siteConfiguration.cart && (
        <Modal
          preventScroll={true}
          isOpen={isCartModalOpen}
          onRequestClose={() => setCartModalOpen(false)}
          size={matchesMd ? 'intrinsic' : 'stretch-x'}
          position={matchesMd ? 'right' : 'center'}
          transition={'slide-from-right'}
          aria={{
            labelledby: 'cart-heading',
          }}
        >
          <Div100vh>
            <StyledMiniCart
              cartConfig={siteConfiguration.cart}
              colorsMap={siteConfiguration.colorsMap}
              showCloseButton={true}
            />
          </Div100vh>
        </Modal>
      )}
      {siteConfiguration && !hideFooter && (
        <Footer
          footer={siteConfiguration.footer}
          newsletter={siteConfiguration.newsletter}
        />
      )}
    </>
  )
}

const MyApp = ({ pageProps, Component }: ProjectAppProps) => {
  const { locale, events } = useRouter()
  const [queryClient] = useState(() => new QueryClient())

  if (!locale) throw new Error('Locale has not been set')

  useEffect(() => {
    setAppElement('#modalContainer')
  }, [])

  useEffect(() => {
    const handleRouteChange = (path: string) => {
      setTag('page_path', path)
    }
    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    setTag('page_locale', locale)
  }, [locale])

  const i18nValue = useMemo(() => {
    return getI18n(locale)
  }, [locale])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ReactQueryDevtools initialIsOpen={false} />
        <I18nextProvider i18n={i18nValue}>
          <ChatAppProvider>
            <CustomerProvider>
              <CartProvider>
                <DiscountsProvider allDiscounts={pageProps.allDiscounts}>
                  {pageProps.siteConfiguration?.seo &&
                    (() => {
                      const { seo } = pageProps.siteConfiguration
                      const seoImage = seoImageLoader(
                        seo.ogImage,
                        seo.ogAltImage,
                      )

                      return (
                        <DefaultSeo
                          title={seo.metaTitle}
                          description={seo.metaDescription}
                          openGraph={{
                            title: seo.ogTitle,
                            description: seo.ogDescription,
                            images: [...(seoImage ? [seoImage] : [])],
                          }}
                          twitter={{
                            cardType: 'summary_large_image',
                          }}
                        />
                      )
                    })()}
                  <Layout {...pageProps}>
                    <Component {...pageProps} />
                  </Layout>
                  <div id={'modalContainer'} />
                </DiscountsProvider>
              </CartProvider>
            </CustomerProvider>
          </ChatAppProvider>
          <Script
            strategy="afterInteractive"
            id="gtm"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
          `,
            }}
          />
          <Script
            strategy="beforeInteractive"
            id="klavyio"
            dangerouslySetInnerHTML={{
              __html:
                '!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();',
            }}
          />
        </I18nextProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}
export default MyApp
