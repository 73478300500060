import { getLocaleRegionIdFromPath } from '@aether/utils'
import { createShopifyClient } from './createShopifyClient'

const COLLECTIONS_BY_PRODUCT_ID_QUERY = `
  query ($id: ID!, $regionId: CountryCode!) @inContext(country: $regionId) {
    product(id: $id) {
      id
      collections(first: 250) {
        edges {
          node {
            id
            title
            handle
          }
        }
      }
    }
  }
`

type CollectionsByProductIdQueryProps = {
  locale: string
  productId: string
}
type CollectionsResponse = {
  product: {
    id: string
    collections: {
      edges: Array<{
        node: {
          id: string
          handle: string
        }
      }>
    }
  }
}

export const getShopifyCollectionsByProductId = async ({
  locale,
  productId,
}: CollectionsByProductIdQueryProps): Promise<string[]> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(locale)
  const shopifyClient = createShopifyClient(localeId)

  const collections = await shopifyClient.request<CollectionsResponse>(
    COLLECTIONS_BY_PRODUCT_ID_QUERY,
    {
      id: productId,
      regionId,
    },
  )

  return (
    collections?.product?.collections?.edges?.map((edge) => edge.node.id) || []
  )
}
