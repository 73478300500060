import { forwardRef, PropsWithChildren } from 'react'
import NextLink, { LinkProps } from 'next/link'
import {
  StyledClickable,
  StyledClickableProps,
} from '../StyledClickable/StyledClickable'
import { LinkAction } from './LinkAction'
import { Action } from '@aether/models'

export type LinkComponentProps = PropsWithChildren<
  StyledClickableProps & {
    href?: LinkProps['href']
    scroll?: LinkProps['scroll']
    active?: boolean
    shallow?: boolean
    target?: '_blank'
    className?: string
    download?: boolean
    action?: Action
    accessibilityLabel?: string
  }
>

export const Link = forwardRef<HTMLLinkElement, LinkComponentProps>(
  (
    {
      action,
      className,
      children,
      scroll,
      appearance = 'defaultLink',
      href,
      target,
      accessibilityLabel,
      download,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onFocus,
      size,
      tabIndex,
      shallow,
      ...restProps
    },
    ref,
  ) => {
    const elementProps = {
      download,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onFocus,
      tabIndex,
      target: href?.startsWith('http') && !target ? '_blank' : target,
      className,
      ariaLabel: accessibilityLabel,
    }
    const stylingProps = {
      appearance,
      size,
    }

    const linkProps = {
      scroll,
      legacyBehavior: true,
      passHref: true,
      shallow,
    }

    if (href && action)
      throw new Error('Link cannot take both href and action props')

    if (href) {
      return (
        <NextLink href={href} {...linkProps}>
          <StyledClickable
            {...stylingProps}
            {...elementProps}
            {...restProps}
            ref={ref}
            as={'a'}
          >
            {children}
          </StyledClickable>
        </NextLink>
      )
    }

    if (action) {
      return (
        <LinkAction
          action={action}
          ref={ref}
          renderButton={(actionProps) => (
            <StyledClickable
              {...stylingProps}
              {...elementProps}
              {...actionProps}
              {...restProps}
              as={'button'}
            >
              {children}
            </StyledClickable>
          )}
        />
      )
    }

    return null
  },
)
