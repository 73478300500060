import { styled } from '@aether/styles'
import { MouseEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'

type SliderIndicatorProps = {
  onSlideClick: (index: number, event?: MouseEvent<any, any>) => void
  activeSlideIndex: number
  images: unknown[]
  className?: string
  id?: string
}

const Root = styled('ol', {
  gridRow: 1,
  gridColumn: 2 / 3,
  display: 'grid',
  gridAutoFlow: 'column',
  padding: '0 11px',
  background: '$gray_light',
  borderRadius: '$rMax',
  alignItems: 'center',
  width: 'min-content',
  height: 'min-content',
  justifySelf: 'end',
  pointerEvents: 'all',
  zIndex: '$content',
})

const Button = styled('button', {
  padding: '8px 4px',
  margin: '0 3px 0 0',
  display: 'grid',
  backgroundColor: '$gray_light',
  variants: {
    isActive: {
      true: {
        '&:before': {
          backgroundColor: '$black',
        },
      },
      false: {
        '&:hover': {
          '&:before': {
            transition: `background ease-in 150ms`,
            backgroundColor: '$gray_light_fourth',
          },
        },
      },
    },
  },
  '&:before': {
    transition: `background ease-in 150ms`,
    alignSelf: 'center',
    justifySelf: 'center',
    width: 6,
    height: 6,
    content: '',
    borderRadius: '$rMax',
    border: '1px solid black',
  },

  '&:focus-visible': {
    outline: '$blue solid 3px',
  },
})

export const SliderIndicator: FC<SliderIndicatorProps> = ({
  onSlideClick,
  activeSlideIndex,
  images,
  className,
  id,
}) => {
  const { t } = useTranslation('product')

  const getSlideLabel = (index: number) => {
    const baseLabel = t('slideIndicatorLabel', { value: index + 1 }) ?? ''
    const selectedSuffix =
      index === activeSlideIndex ? `, ${t('currentlySelected')}` : ''
    return `${baseLabel}${selectedSuffix}`
  }

  return (
    <Root
      className={className}
      aria-label={t('stackImagesSliderIndicatorsListLabel') ?? ''}
    >
      {images.map((_, i) => (
        <li key={`${id}-${i}`}>
          <Button
            onClick={(event) => onSlideClick(i, event)}
            isActive={i === activeSlideIndex}
            aria-label={getSlideLabel(i)}
            aria-pressed={i === activeSlideIndex}
            aria-controls={`image-${id}-${i}`}
          />
        </li>
      ))}
    </Root>
  )
}
