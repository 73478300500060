import { FC, useState, MouseEventHandler, AnimationEventHandler } from 'react'
import {
  SanityImageMediaPayload,
  ShopifyImageMediaPayload,
} from '@aether/models'
import { Media } from '../Media/Media'
import { styled, keyframes } from '@aether/styles'
import { usePrevious } from '@aether/utils'
import { useIsomorphicLayoutEffect } from '@aether/utils'
import { MediaSizesProps } from '../Media/helpers/buildSizes'
import { SlideButton } from '../SlideButton/SlideButton'
import { useTranslation } from 'react-i18next'

type StackImagesSliderProps = {
  images: (SanityImageMediaPayload | ShopifyImageMediaPayload)[]
  isUnfolded: boolean
  activeSlideIndex: number
  onNextSlideClick?: MouseEventHandler<HTMLButtonElement>
  onPrevSlideClick?: MouseEventHandler<HTMLButtonElement>
  onAnimationEnd?: AnimationEventHandler<HTMLLIElement>
  onAnimationStart?: AnimationEventHandler<HTMLLIElement>
  showArrows?: boolean
  mediaSizes?: MediaSizesProps
  id?: string
}

// animation config
const ANIMATION_DURATION_MS = 350
// offsets between cards in uunfolded state
export const STACK_IMAGE_OFFSET_Y = 8
export const STACK_IMAGE_OFFSET_X = 15
// distance slide will travel when fading as the first item in the stack
const THROW_DISTANCE_X = 0
const THROW_DISTANCE_Y = 0
// distance slide will travel when fading in as last item in stack
const FADE_IN_OFFSET_RATIO = 1.05
// angle of the slide when fading as the first item in the stack
const FADE_DEGREE = 0
// controls how the stages of animation should be distributed during animation time
const FADE_OUT_STAGE_END_TIME_PERCENT = 45
const FADE_IN_STAGE_START_TIME_PERCENT = 65

const Slide = styled('li', {
  minWidth: '100%',
  height: '100%',
  gridColumn: '1',
  gridRow: '1/1',
  transition: `transform ${ANIMATION_DURATION_MS}ms cubic-bezier(0.26, 0.02, 0.27, 0.97) 0s`,
})

const Root = styled('div', {
  width: '100%',
  display: 'grid',
  height: '100%',
  position: 'relative',
})

const ImagesList = styled('ul', {
  width: '100%',
  display: 'grid',
  height: '100%',
  position: 'relative',
})

const getTransformStyles = (
  isUnfolded: boolean,
  activeSlideIndex: number,
  slideIndex: number,
  slidesAmount: number,
): string => {
  const isCurrentSlide = slideIndex === activeSlideIndex
  const prevSlidesAmount = slidesAmount - (slidesAmount - activeSlideIndex)
  const nextSlidesAmunt = slidesAmount - prevSlidesAmount

  const stackPosition = (() => {
    if (isCurrentSlide) return 0

    if (slideIndex > activeSlideIndex) return slideIndex - activeSlideIndex

    return nextSlidesAmunt + slideIndex
  })()

  if (!isUnfolded || isCurrentSlide) {
    return 'translateX(0px)'
  }

  return `translate(${stackPosition * STACK_IMAGE_OFFSET_X}px, -${
    stackPosition * STACK_IMAGE_OFFSET_Y
  }px)`
}

const getAnimationStyles = (
  isReversed: boolean,
  slidesAmount: number,
  activeSlideIndex: number,
  prevSlideIndex: number,
  slideIndex: number,
  isAnimating: boolean,
  isUnfolded: boolean,
): string => {
  const isCurrentSlide = slideIndex === activeSlideIndex
  const wasCurrentSlide = slideIndex === prevSlideIndex

  const isNextOrPrev =
    activeSlideIndex + 1 === prevSlideIndex ||
    activeSlideIndex - 1 === prevSlideIndex ||
    (prevSlideIndex === 0 && activeSlideIndex === slidesAmount - 1) ||
    (activeSlideIndex === 0 && prevSlideIndex === slidesAmount - 1)

  if (!isNextOrPrev) {
    return ''
  }

  if (!isAnimating) {
    return ''
  }

  if (isReversed && !isCurrentSlide) {
    return ''
  }

  if (!isReversed && !wasCurrentSlide) {
    return ''
  }

  const animationnDirection = isReversed ? 'reverse' : 'normal'
  const lastSlidePosition = slidesAmount - 1
  const startZindex = slidesAmount + 1
  const endZindex = 0

  const initialTransform = 'rotate(0deg) translateX(0px)'

  const throwTransform = `rotate(${FADE_DEGREE}deg) translate(calc(-1*${THROW_DISTANCE_X}px),calc(${THROW_DISTANCE_Y}px*-1))`

  const fadeInTransform = (() => {
    if (isUnfolded) {
      return `rotate(0deg) translate(${
        lastSlidePosition * STACK_IMAGE_OFFSET_X * FADE_IN_OFFSET_RATIO
      }px, -${
        lastSlidePosition * STACK_IMAGE_OFFSET_Y * FADE_IN_OFFSET_RATIO
      }px)`
    }

    return initialTransform
  })()

  const endTransform = (() => {
    if (isUnfolded) {
      return `rotate(0deg) translate(${
        lastSlidePosition * STACK_IMAGE_OFFSET_X
      }px, -${lastSlidePosition * STACK_IMAGE_OFFSET_Y}px)`
    }

    return initialTransform
  })()

  const animationChain = keyframes({
    '0%': {
      opacity: 1,
      transform: 'rotate(0deg) translateX(0px)',
      zIndex: startZindex,
      animationTimingFunction: 'cubic-bezier(0.71,0.01,0.59,0.97)',
    },
    [`${FADE_OUT_STAGE_END_TIME_PERCENT}%`]: {
      opacity: 0,
      zIndex: startZindex,
      transform: throwTransform,
      animationTimingFunction: 'cubic-bezier(0.71,0.01,0.59,0.97)',
    },
    [`${FADE_IN_STAGE_START_TIME_PERCENT}%`]: {
      opacity: 0,
      zIndex: endZindex,
      transform: fadeInTransform,
      animationTimingFunction: 'cubic-bezier(0.26,0.02,0.27,0.97)',
    },
    to: {
      opacity: 1,
      zIndex: endZindex,
      transform: endTransform,
      animationTimingFunction: 'cubic-bezier(0.26,0.02,0.27,0.97)',
    },
  })

  return `${ANIMATION_DURATION_MS}ms ease 0s 1 ${animationnDirection} none running ${animationChain}`
}

const StyledNextSlideButton = styled(SlideButton, {
  position: 'absolute',
  top: '50%',
  right: '10px',
  pointerEvents: 'all',
  zIndex: '$content',
})

const StyledPrevSlideButton = styled(SlideButton, {
  position: 'absolute',
  top: '50%',
  left: '10px',
  pointerEvents: 'all',
  zIndex: '$content',
})

const getZIndexStyles = (
  activeSlideIndex: number,
  slideIndex: number,
  slidesAmount: number,
): number => {
  const isCurrentSlide = slideIndex === activeSlideIndex
  const prevSlidesAmount = slidesAmount - (slidesAmount - activeSlideIndex)
  const nextSlidesAmunt = slidesAmount - prevSlidesAmount

  const stackPosition = (() => {
    if (isCurrentSlide) return 0

    if (slideIndex > activeSlideIndex) return slideIndex - activeSlideIndex

    return nextSlidesAmunt + slideIndex
  })()

  return slidesAmount - stackPosition
}

export const StackImagesSlider: FC<StackImagesSliderProps> = ({
  images,
  isUnfolded,
  activeSlideIndex,
  onNextSlideClick,
  onAnimationEnd,
  onAnimationStart,
  onPrevSlideClick,
  mediaSizes,
  id,
}) => {
  const { t } = useTranslation('product')
  const [isAnimating, setIsAnimating] = useState(false)
  const slidesAmount = images.length
  const prevSlideIndex = usePrevious(activeSlideIndex) || 0

  const isReversed = (() => {
    if (prevSlideIndex === slidesAmount - 1 && activeSlideIndex === 0) {
      return false
    }
    if (activeSlideIndex === slidesAmount - 1 && prevSlideIndex === 0) {
      return true
    }

    return activeSlideIndex < prevSlideIndex
  })()

  // add animation on slide change
  useIsomorphicLayoutEffect(() => {
    setIsAnimating(true)
  }, [activeSlideIndex])

  // remove animation on end
  const handleAnimationEnd: AnimationEventHandler<HTMLLIElement> = (event) => {
    setIsAnimating(false)
    onAnimationEnd && onAnimationEnd(event)
  }

  return (
    <Root aria-live="polite" aria-label={t('stackImagesSliderListLabel') ?? ''}>
      <ImagesList>
        {images.map((img, slideIndex) => (
          <Slide
            id={`image-${id}-${slideIndex}`}
            key={`image-${slideIndex}`}
            aria-hidden={slideIndex !== activeSlideIndex}
            aria-roledescription="slide"
            aria-label={`Image ${slideIndex + 1} of ${images.length}`}
            role="group"
            onAnimationEnd={handleAnimationEnd}
            onAnimationStart={onAnimationStart}
            css={{
              transform: getTransformStyles(
                isUnfolded,
                activeSlideIndex,
                slideIndex,
                slidesAmount,
              ),
              animation: getAnimationStyles(
                isReversed,
                slidesAmount,
                activeSlideIndex,
                prevSlideIndex,
                slideIndex,
                isAnimating,
                isUnfolded,
              ),
              zIndex: getZIndexStyles(
                activeSlideIndex,
                slideIndex,
                slidesAmount,
              ),
            }}
          >
            <Media
              mediaPayload={img}
              hardcropRatio="portrait1"
              sizes={mediaSizes}
              withBackground
            />
          </Slide>
        ))}
      </ImagesList>
      {images.length > 1 && (
        <>
          <StyledNextSlideButton
            handleClick={onNextSlideClick}
            isVisible={onNextSlideClick && isUnfolded}
            arrowDirection="right"
            ariaLabel={t('nextImage') ?? ''}
          />

          <StyledPrevSlideButton
            handleClick={onPrevSlideClick}
            isVisible={onNextSlideClick && isUnfolded}
            arrowDirection="left"
            ariaLabel={t('prevImage') ?? ''}
          />
        </>
      )}
    </Root>
  )
}
