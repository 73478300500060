export const Logo = ({ fill = 'black' }: { fill?: string }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.833 0L0 24.8276H4.21053L6.1706 20.5445H17.1325L19.0926 24.8276H23.5209L12.196 0H11.833ZM15.649 17.0599L11.7604 8.13067L7.76769 17.0599H15.649Z"
        fill={fill}
      />
      <path
        d="M54.1562 0.798828H40.1453V24.8279H54.3014V21.0529H44.2833V14.0112H52.7769V10.3814H44.2833V4.50119H54.1562V0.798828Z"
        fill={fill}
      />
      <path
        d="M82.9038 24.8279H78.6933V4.42859H70.5626V0.798828H90.9619V4.42859H82.9038V24.8279Z"
        fill={fill}
      />
      <path
        d="M112.523 0.798828H108.312V24.8279H112.523V14.0838H123.993V24.8279H128.131V0.798828H123.993V10.3814H112.523V0.798828Z"
        fill={fill}
      />
      <path
        d="M148.312 0.798828H162.323V4.50119H152.45V10.3814H160.944V14.0112H152.45V21.0529H162.468V24.8279H148.312V0.798828Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.151 0.798828H181.561V24.8279H185.699V14.8823H187.731L195.064 24.8279H200L192.232 14.3016C195.572 13.14 197.459 10.7444 197.459 7.62279C197.459 5.59012 196.443 3.70264 194.628 2.46852C192.595 1.01661 190.2 0.798828 187.151 0.798828ZM193.249 7.91317C193.249 10.3814 191.361 11.7607 187.659 11.7607H185.699V4.21081H187.296C191.216 4.21081 193.249 5.29974 193.249 7.91317Z"
        fill={fill}
      />
    </svg>
  )
}
