import { groq } from 'next-sanity'
import { getClient } from '../getClient'
import { createLinkProjection } from '../projections/createLinkProjection'
import { createMediaProjection } from '../projections/createMediaProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { VipAccountContent } from '@aether/models'

interface GetAccountResponse {
  vip: VipAccountContent
}

export const getAccountContent = async (
  localeId: string,
  preview = false,
): Promise<GetAccountResponse> => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)

  const vipContentQuery = groq`
    {
      "vipLinks": *[_type == "insiderEarlyAccessCards"][0].earlyAccessCards[] {
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('subtitle', localeId)},
        backgroundStyle,
        link${LINK_PROJECTION},
        ${coalesceLocaleField('linkLabel', localeId)},
        media${MEDIA_PROJECTION}
      },
      "benefits": *[_type == "insiderBenefits"][0] {
        items[] {
          ${coalesceLocaleField('title', localeId)},
          ${coalesceLocaleField('description', localeId)},
          ${coalesceLocaleField('accountPageDescription', localeId)}
        }
      }
    }
  `

  return {
    vip: await getClient(preview).fetch(vipContentQuery),
  }
}
