import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'

export const sanityImageProjection = (fieldName: string) => groq`{
  ...${fieldName},
  ...${fieldName}.asset -> {
    "width": metadata.dimensions.width,
    "height": metadata.dimensions.height
  }
}`

const MUX_VIDEO_PROJECTION = groq`{
    "width": data.static_renditions.files[-1].width,
    "height": data.static_renditions.files[-1].height,
    "mp4": "https://stream.mux.com/" + data.playback_ids[0].id + "/" + {"filesByQuality": data.static_renditions.files | order(bitrate desc)}.filesByQuality[0].name,
    "aspectRatio": data.aspect_ratio,
    "firstFrame": "https://image.mux.com/" + data.playback_ids[0].id + "/thumbnail.jpg?time=0",
    "id": data.id,
}`

export const createMediaProjection = (localeId: string) => groq`{
    ${coalesceLocaleField('alt', localeId)},
    mediaType == 'image' => {
      "mediaPayload": {
        "type": "sanity-image",
        "image": ${sanityImageProjection('image')},
        "isDecorative": coalesce(!conveysInformation, true),
        overrideMobileImage && defined(mobileImage) => {
          "mobileImage": ${sanityImageProjection('mobileImage')}
        }
      }
    },
    mediaType == 'video' => {
      "mediaPayload": {
        "type": "mux-video",
        "video": video.asset -> ${MUX_VIDEO_PROJECTION},
        "mobileVideo": mobileVideo.asset -> ${MUX_VIDEO_PROJECTION}
      }
    },
   }`
