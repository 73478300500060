import { ShopifyPrice } from '@aether/models'
import { FC } from 'react'
import { PricePrimitive } from '../PricePrimitive/PricePrimitive'
import { styled } from '@aether/styles'
import {
  PromoPriceWrapper,
  PromoPriceWrapperProps,
} from '../../PromoPriceWrapper/PromoPriceWrapper'

const StyledWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3,max-content)',
})

export const PriceCompare: FC<{
  price?: ShopifyPrice
  compareAtPrice?: ShopifyPrice
  promoPriceColor?: PromoPriceWrapperProps
}> = ({ price, compareAtPrice, promoPriceColor = 'red' }) => {
  if (!price) return null

  const isCompareAtPrice = !!(
    compareAtPrice &&
    parseFloat(compareAtPrice.amount) > parseFloat(price.amount)
  )

  return (
    <StyledWrap>
      {isCompareAtPrice ? (
        <>
          <s>
            <PricePrimitive price={compareAtPrice} testId={'compareAtPrice'} />
          </s>
          <p>&nbsp;</p>
          <PromoPriceWrapper color={promoPriceColor}>
            <PricePrimitive price={price} testId={'price'} />
          </PromoPriceWrapper>
        </>
      ) : (
        <PricePrimitive price={price} testId={'price'} />
      )}
    </StyledWrap>
  )
}
