import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@aether/configs'
import { createMediaProjection } from './createMediaProjection'

export const createSizeGuideProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`
        *[_type == "sizeGuide"] {
            shopifyId,
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('caption', localeId)},
            table {
              rows[]{
                 cells[] {
                  ${coalesceLocaleField('title', localeId)},
                  cellType,
                  cmUnit,
                  inchUnit,
                }
              }
            },
            fitGuide[] {
                   ${coalesceLocaleField('title', localeId)},
                   ${coalesceLocaleField('description', localeId)},
                   image${MEDIA_PROJECTION},
                 },
            howToMeasure[] {
                   ${coalesceLocaleField('title', localeId)},
                   ${coalesceLocaleField('description', localeId)},
                 },
            howToMeasureImage${MEDIA_PROJECTION},
        }
  `
}
