import { InsiderPage } from '@aether/models'
import { getClient } from '../getClient'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { getLocaleRegionIdFromPath } from '@aether/utils'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createSEOProjection } from '../projections/createSEOProjection'
export const getInsiderPage = async (
  locale: string,
  preview = false,
): Promise<InsiderPage | null> => {
  const [, localeId] = getLocaleRegionIdFromPath(locale)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const SEO_PROJECTION = createSEOProjection(localeId)

  const query = `*[_type == "insiderPage"][0]{
    hero {
      ${coalesceLocaleField('title', localeId)},
      ${coalesceLocaleField('description', localeId)},
      productLink->{
        "id": store.gid,
        handle,
        "variantId": store.variants[0] -> {
          "id": _id
        }.id
      },
      media${MEDIA_PROJECTION},
    },
    ${coalesceLocaleField('mainCopy', localeId)},
    benefits -> {
      ${coalesceLocaleField('title', localeId)},
      items[] {
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('description', localeId)},
      }
    },
    imageCarousel {
      ${coalesceLocaleField('title', localeId)},
      images[] {
        media${MEDIA_PROJECTION}
      }
    },
    faq {
      ${coalesceLocaleField('title', localeId)},
      items[] {
        ${coalesceLocaleField('question', localeId)},
        ${coalesceLocaleField('answer', localeId)}
      }
    },
    "seo": seo${SEO_PROJECTION},
  }`

  const page = await getClient(preview).fetch(query)

  if (!page) {
    return null
  }

  return page
}
