import { PageLink } from '@aether/models'
import { FC } from 'react'
import { Link, LinkComponentProps } from '../Link/Link'
import { Media } from '../Media/Media'
import { MediaProps } from '../Media/models/MediaProps'
import { styled } from '@aether/styles'

type Props = {
  link?: PageLink
  media: MediaProps
  noIndex?: boolean
  mode?: 'default' | 'wrapMedia'
}

const Wrap = styled('div', {
  display: 'grid',
})
const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  pointerEvents: 'none',
})
const LinkWrap = styled('div', {
  display: 'grid',
  gridColumn: 1,
  gridRow: 1,
})

export const MediaLink: FC<Props> = ({
  link,
  media,
  noIndex = true,
  mode = 'default',
}) => {
  if (link?.href || link?.action) {
    const linkProps: LinkComponentProps = {
      // by default we set link to display block, can be overwritten by link props
      appearance: 'block',
      ...(noIndex ? { tabIndex: -1 } : {}),
      ...link,
    }

    if (mode === 'wrapMedia') {
      return (
        <Link {...linkProps}>
          <Media {...media} withBackground />
        </Link>
      )
    }

    return (
      <Wrap>
        <MediaWrap>
          <Media {...media} withBackground />
        </MediaWrap>
        <LinkWrap>
          <Link {...linkProps} tabIndex={-1} aria-hidden={true} />
        </LinkWrap>
      </Wrap>
    )
  }

  return <Media {...media} withBackground />
}
