import { useId } from 'react'

export const Mute = () => {
  const filterId = useId()
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter={`url(#${filterId})`}>
        <circle cx="16" cy="16" r="16" fill="white" fillOpacity="0.1" />
        <circle cx="16" cy="16" r="15.5" stroke="white" />
      </g>
      <path
        d="M8 13.2004V19.3169H11.2774L15.5837 22.156V10.3422L11.1631 13.1813H8.01905L8 13.2004Z"
        fill="white"
      />
      <path
        d="M18.0227 11.6951C17.8131 11.8666 17.794 12.1524 17.9465 12.362C19.4899 14.2484 19.4899 17.621 17.9465 19.5646C17.794 19.7742 17.8131 20.0791 18.0227 20.2315C18.118 20.3077 18.2132 20.3268 18.3276 20.3268C18.4609 20.3268 18.6134 20.2696 18.7087 20.1553C20.4998 17.8878 20.4998 13.9626 18.7087 11.7522C18.5372 11.5426 18.2513 11.5236 18.0417 11.676L18.0227 11.6951Z"
        fill="white"
      />
      <path
        d="M21.0714 10.3422C20.8618 10.5137 20.8428 10.7995 20.9952 11.0091C23.1102 13.6196 23.1102 18.2498 20.9952 20.9365C20.8428 21.1461 20.8618 21.451 21.0714 21.6034C21.1667 21.6796 21.262 21.6987 21.3763 21.6987C21.5097 21.6987 21.6621 21.6415 21.7574 21.5272C24.1392 18.5356 24.1392 13.3338 21.7574 10.4184C21.5859 10.2088 21.3001 10.1898 21.0905 10.3422H21.0714Z"
        fill="white"
      />
      <defs>
        <filter
          id={filterId}
          x="-4"
          y="-4"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1207_3575"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1207_3575"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
