import React from 'react'

export const AccountFill = ({ fill = 'black' }: { fill?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.78 13.75C16.1 12.27 15.12 11.49 13.94 11.49H5.53C4.37 11.49 3.41 12.26 2.76 13.72C2.28 14.79 2 16.19 2 17.48C2 19.47 2.68 19.76 3.09 19.76H16.49C16.9 19.76 17.59 19.47 17.59 17.55C17.59 16.29 17.28 14.84 16.79 13.76L16.78 13.75Z"
        fill={fill}
      />
      <path
        d="M9.79 9.32C12.5293 9.32 14.75 7.23365 14.75 4.66C14.75 2.08635 12.5293 0 9.79 0C7.05067 0 4.83 2.08635 4.83 4.66C4.83 7.23365 7.05067 9.32 9.79 9.32Z"
        fill={fill}
      />
    </svg>
  )
}
