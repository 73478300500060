import { useId } from 'react'

export const Pause = () => {
  const filterId = useId()
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter={`url(#${filterId})`}>
        <circle cx="16" cy="16" r="16" fill="white" fillOpacity="0.1" />
        <circle cx="16" cy="16" r="15.5" stroke="white" />
      </g>
      <path
        d="M12.1388 22.8C11.5125 22.8 11 22.2875 11 21.6612V11.1388C11 10.5125 11.5125 10 12.1388 10C12.7651 10 13.2776 10.5125 13.2776 11.1388V21.6612C13.2776 22.2875 12.7651 22.8 12.1388 22.8Z"
        fill="white"
      />
      <path
        d="M19.9167 22.8C19.2904 22.8 18.7779 22.2875 18.7779 21.6612V11.1388C18.7779 10.5125 19.2904 10 19.9167 10C20.5431 10 21.0555 10.5125 21.0555 11.1388V21.6612C21.0555 22.2875 20.5431 22.8 19.9167 22.8Z"
        fill="white"
      />
      <defs>
        <filter
          id={filterId}
          x="-4"
          y="-4"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_5216_17376"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5216_17376"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
