import { CSS, styled } from '@aether/styles'
import React, { forwardRef, type PropsWithChildren, type AriaRole } from 'react'

export const Dot = styled('div', {
  width: '$8',
  height: '$8',
  borderRadius: '$rMax',
  backgroundColor: 'currentColor',
  marginLeft: '$8',
  position: 'absolute',
  left: 0,
  transform: 'scale(0)',
  transition: 'all .3s',
})
export const Placeholder = styled('div', {
  width: 0,
  transition: 'all .5s',
})

const badgeOutlineStyles: CSS = {
  display: 'inline-flex',
  lineHeight: 1,
  borderRadius: '$r1',
  padding: '0 $12',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background .4s, color .4s',
  $focusStyle: 'default',
}

const primaryLinkBaseStyles: CSS = {
  whiteSpace: 'nowrap',
  maxWidth: '100%',
  display: 'inline-flex',
  position: 'relative',
  alignItems: 'center',
  overflow: 'hidden',
  padding: '0 $12',
  color: 'currentColor',
  fill: 'currentColor',
  border: 'currentColor solid 1px',
  $aetherFont: 'ui02',
  borderRadius: '$r1',
  lineHeight: 0,
  transition: 'margin-right .5s',
  marginRight: 'calc($8 + $12)',
  '&:hover:not([disabled])': {
    marginRight: 0,
    [`& ${Placeholder}`]: {
      width: 'calc($8 + $12)',
    },
    [`& ${Dot}`]: {
      transform: 'scale(1)',
    },
  },
  $focusStyle: 'default',
  '&:disabled, &[disabled]': {
    opacity: 0.5,
    cursor: 'default',
    '&:hover': {
      [`& ${Placeholder}`]: {
        width: 0,
      },
      [`& ${Dot}`]: {
        transform: 'scale(0)',
      },
    },
  },
}

export const StyledClickableWrap = styled('button', {
  variants: {
    appearance: {
      primary: {
        ...primaryLinkBaseStyles,
        height: '$buttonHeightM',
        background: 'transparent',
      },
      primaryLightWhite: {
        ...primaryLinkBaseStyles,
        height: '$buttonHeightM',
        background: '$white_light',
        backdropFilter: 'blur(4px)',
      },
      primarySolidBlack: {
        ...primaryLinkBaseStyles,
        height: '$buttonHeightM',
        color: '$white',
        background: '$black',
        borderColor: '$black',
      },
      primarySolidWhite: {
        ...primaryLinkBaseStyles,
        height: '$buttonHeightM',
        color: '$black',
        background: '$white',
        borderColor: '$white',
      },
      textSimple: {
        borderBottom: '1px solid',
        '&:hover': {
          color: '$blue',
        },
        $focusStyle: 'default',
      },
      defaultLink: {
        lineHeight: 1,
        borderRadius: '$r2',
        transition: 'opacity ease-in 200ms',
        '&:hover': {
          opacity: 0.7,
        },
        $focusStyle: 'default',
        '&:disabled, &[disabled]': {
          opacity: 0.6,
          cursor: 'default',
        },
      },
      badgeWhiteOutline: {
        background: '$white',
        display: 'inline-flex',
        $aetherFont: 'ui02',
        lineHeight: 1,
        borderRadius: '$r1',
        height: '$buttonHeightS',
        padding: '0 $20',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background .4s, color .4s',
        border: '1px solid $black',
        '&:hover': {
          background: '$black',
          color: '$white',
        },
        $focusStyle: 'default',
      },
      badge: {
        display: 'inline-flex',
        $aetherFont: 'ui02',
        lineHeight: 1,
        borderRadius: '$r1',
        height: '$buttonHeightS',
        padding: '0 $20',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background .4s, color .4s',
        '&:hover': {
          background: '$black',
          color: '$white',
        },
        $focusStyle: 'default',
      },
      badgeSlim: {
        display: 'inline-flex',
        $aetherFont: 'ui02',
        lineHeight: 'normal',
        borderRadius: '$r1',
        height: '$buttonHeightS',
        padding: '0 $12',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background .4s, color .4s',
        '&:hover': {
          background: '$black',
          color: '$white',
        },
        $focusStyle: 'default',
      },
      badgeOutline: {
        $aetherFont: 'ui02',
        height: '$buttonHeightS',
        border: '$black solid 1px',
        ...badgeOutlineStyles,
        '&:hover': {
          background: '$black',
          color: '$white',
          border: 'transparent solid 1px',
        },
      },
      badgeOutlineBig: {
        $aetherFont: 'ui02',
        height: '$buttonHeightL',
        border: 'currentColor solid 1px',
        ...badgeOutlineStyles,
        '&:hover': {
          background: '$black',
          color: '$white',
        },
      },
      badgeOutlineWhite: {
        $aetherFont: 'ui03',
        color: '$white',
        height: '$buttonHeightS',
        border: '$white solid 1px',
        ...badgeOutlineStyles,
        '&:hover': {
          background: '$white',
          color: '$black',
        },
      },

      badgeBlack: {
        backgroundColor: '$black',
        color: '$white',
        display: 'inline-flex',
        $aetherFont: 'ui02',
        lineHeight: 1,
        borderRadius: '$r1',
        height: '$buttonHeightL',
        padding: '0 $20',
        alignItems: 'center',
        justifyContent: 'center',
        $focusStyle: 'default',
      },
      badgeBlue: {
        backgroundColor: '$blue',
        color: '$white',
        display: 'inline-flex',
        $aetherFont: 'ui02',
        width: '100%',
        height: '$40',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '$r1',
        padding: '0 $20',
        transition: 'opacity ease-in 200ms',
        $focusStyle: 'default',
        '&:disabled, &[disabled]': {
          cursor: 'default',
        },
      },
      badgeWhite: {
        display: 'inline-flex',
        $aetherFont: 'ui02',
        lineHeight: 1,
        borderRadius: '$r1',
        height: '$buttonHeightS',
        padding: '0 $20',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background .4s, color .4s',
        background: '$white',
        '&:hover': {
          background: '$black',
          color: '$white',
        },
        $focusStyle: 'default',
      },

      badgeGray: {
        backgroundColor: '$gray_light',
        display: 'inline-flex',
        $aetherFont: 'ui02',
        lineHeight: '22px',
        borderRadius: '$r1',
        height: '$buttonHeightS',
        padding: '0 $12',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background .4s, color .4s',
        '&:hover': {
          background: '$black',
          color: '$white',
        },
        $focusStyle: 'default',
        '&:disabled, &[disabled]': {
          opacity: 0.5,
          cursor: 'default',
        },
      },
      flex: {
        display: 'flex',
      },
      block: {
        display: 'block',
        cursor: 'pointer',
        $focusStyle: 'default',
      },
      grid: {
        display: 'grid',
      },
    },
    active: {
      true: {},
      false: {},
    },
    isDisabled: {
      true: {},
      false: {},
    },
    size: {
      XL: {},
      L: {},
      S: {},
      M: {},
    },
  },
  compoundVariants: [
    {
      appearance: 'primary',
      active: true,
      css: {
        marginRight: 0,
        [`& ${Placeholder}`]: {
          width: 'calc($8 + $12)',
        },
        [`& ${Dot}`]: {
          transform: 'scale(1)',
        },
        '&:disabled, &[disabled]': {
          '&:hover': {
            [`& ${Placeholder}`]: {
              width: 0,
            },
            [`& ${Dot}`]: {
              transform: 'scale(0)',
            },
          },
        },
      },
    },
    {
      appearance: 'badge',
      active: true,
      css: {
        background: '$black',
        color: '$white',
      },
    },
    {
      appearance: 'badge',
      active: false,
      css: {
        '&:hover': {
          background: '$gray_light_third',
          color: '$black',
        },
      },
    },
    {
      appearance: 'badgeSlim',
      active: true,
      css: {
        background: '$black',
        color: '$white',
      },
    },
    {
      appearance: 'badgeSlim',
      active: false,
      css: {
        '&:hover': {
          background: '$gray_light_third',
          color: '$black',
        },
      },
    },
    {
      // TODO: update with proper disabled button styles
      appearance: 'badgeBlack',
      isDisabled: true,
      css: {
        opacity: 0.2,
      },
    },
    {
      appearance: 'badgeGray',
      size: 'L',
      css: {
        height: '$buttonHeightL',
      },
    },
    {
      appearance: 'badgeBlack',
      size: 'M',
      css: {
        height: '$buttonHeightM',
      },
    },
    {
      appearance: 'badgeWhite',
      size: 'M',
      css: {
        height: '$buttonHeightM',
      },
    },
    {
      appearance: 'badgeBlack',
      size: 'L',
      css: {
        height: '$buttonHeightL',
      },
    },
    {
      appearance: 'badgeBlack',
      size: 'XL',
      css: {
        borderRadius: '$rMax',
        height: '$buttonHeightXL',
      },
    },
    {
      appearance: 'badgeGray',
      size: 'XL',
      css: {
        borderRadius: '$rMax',
        height: '$buttonHeightXL',
      },
    },
    {
      appearance: 'badgeBlack',
      size: 'S',
      css: {
        height: '$buttonHeightS',
      },
    },
    {
      appearance: 'blackWhiteOutline',
      size: 'M',
      css: {
        height: '$buttonHeightM',
      },
    },
    {
      appearance: 'primary',
      size: 'M',
      css: {
        height: '$buttonHeightM',
      },
    },
  ],
  padding: 0,
  background: 'transparent',
  color: 'currentColor',
  cursor: 'pointer',
})

type Appearance =
  | 'primary'
  | 'flex'
  | 'block'
  | 'grid'
  | 'defaultLink'
  | 'badge'
  | 'badgeSlim'
  | 'badgeGray'
  | 'badgeBlack'
  | 'badgeBlue'
  | 'badgeWhite'
  | 'badgeOutline'
  | 'badgeOutlineWhite'
  | 'badgeOutlineBig'
  | 'primaryLightWhite'
  | 'primarySolidWhite'
  | 'primarySolidBlack'
  | 'textSimple'
  | 'badgeWhiteOutline'

type Size = 'S' | 'M' | 'L' | 'XL'

export type StyledClickableProps = PropsWithChildren<{
  appearance?: Appearance
  size?: Size
  active?: boolean
  tabIndex?: number
  disabled?: boolean
  className?: string
  as?: any
  href?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onFocus?: () => void
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
  type?: 'button' | 'submit' | 'reset'
  ariaLabel?: string
  id?: string
  role?: AriaRole
}>

export const StyledClickable = forwardRef<any, StyledClickableProps>(
  ({ children, appearance, size, disabled, ariaLabel, ...restProps }, ref) => {
    return (
      <StyledClickableWrap
        aria-label={ariaLabel}
        ref={ref}
        appearance={appearance}
        {...restProps}
        disabled={disabled}
        isDisabled={disabled}
        size={size}
      >
        {(appearance === 'primary' ||
          appearance === 'badgeWhite' ||
          appearance === 'primarySolidWhite' ||
          appearance === 'primarySolidBlack' ||
          appearance === 'primaryLightWhite') && (
          <>
            <Dot />
            <Placeholder />
          </>
        )}
        {children}
      </StyledClickableWrap>
    )
  },
)
