import { FC } from 'react'
import imageUrlBuilder from '@sanity/image-url'
import { getClient } from '@aether/services/sanity-service'
import { ImageLoader } from 'next/image'

import { SanityImageMediaPayload, SanityImageType } from '@aether/models'

import { MediaProps } from '../models/MediaProps'
import { getMediaSizeByRatio } from '../helpers/getMediaSizeByRatio'
import { ResponsiveMediaComponent } from './ResponsiveMediaComponent'
import { Image } from './Image'

type SanityImageProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: SanityImageMediaPayload
}

type ImageAdapterProps = Omit<MediaProps, 'mediaPayload'> & {
  image: SanityImageType
  blur?: boolean
  isDecorative?: boolean
}

const builder = imageUrlBuilder(getClient(false))

const ImageAdapter: FC<ImageAdapterProps> = ({
  alt,
  hardcropRatio,
  objectFit,
  priority,
  sizes,
  layout,
  image,
  blur = false,
  isDecorative = true,
}) => {
  if (!image?.asset) {
    return null
  }

  const buildedImage = builder.image(image)

  const loader: ImageLoader = ({ width: loaderWidth, quality }) => {
    const [width, height] = getMediaSizeByRatio({
      width: loaderWidth,
      ratioType: hardcropRatio,
    })

    if (height && width) {
      return buildedImage
        .width(width)
        .height(height)
        .quality(quality || 75)
        .auto('format')
        .url() as string
    }

    if (width) {
      return buildedImage
        .width(width)
        .quality(quality || 75)
        .auto('format')
        .url() as string
    }

    return buildedImage
      .quality(quality || 75)
      .auto('format')
      .url() as string
  }

  const [width, height] = getMediaSizeByRatio({
    width: image.width,
    crop: image.crop,
    ratioType: hardcropRatio,
    height: image.height,
  })

  const url = (() => {
    try {
      return buildedImage.url()
    } catch {
      return undefined
    }
  })()

  if (!url) {
    return null
  }

  return (
    <Image
      alt={isDecorative ? '' : (alt || image.alt) ?? ''}
      objectFit={objectFit}
      priority={priority}
      src={url}
      sizes={sizes}
      layout={layout}
      width={width}
      height={height}
      loader={loader}
      placeholder={blur ? 'blur' : 'empty'}
    />
  )
}

export const SanityImage: FC<SanityImageProps> = ({
  mediaPayload,
  ...restOfProps
}) => {
  const { image, mobileImage, isDecorative } = mediaPayload

  return (
    <ResponsiveMediaComponent
      mobileComponent={
        mobileImage && (
          <ImageAdapter
            image={mobileImage}
            isDecorative={isDecorative}
            {...restOfProps}
          />
        )
      }
      component={
        <ImageAdapter
          image={image}
          isDecorative={isDecorative}
          {...restOfProps}
        />
      }
    />
  )
}
